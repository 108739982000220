<template>
  <div
    class="borescoping px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent title="Borescoping" size="large" />
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <div
          class="fusion-column-wrapper"
          style="background-position: left top; background-repeat: no-repeat; background-size: cover; height: auto;"
          data-bg-url=""
        >
          <span
            class="fusion-imageframe imageframe-none imageframe-1 hover-type-none"
            ><img
              src="@/assets/RiskManagement/borescoping-min.jpg"
              width="640"
              height="410"
              alt=""
              title="borescoping"
              class="img-responsive wp-image-12658"
              sizes="(max-width: 800px) 100vw, 600px"
          /></span>
          <div class="fusion-clearfix"></div>
        </div>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <Paragraph
          ><p>
            SMS has successfully applied borescope inspection for hazards
            assessment and risk characterization to a variety of situations,
            equipment, and conditions.&nbsp; SMS has performed hazards analysis
            on the use of borescope equipment and developed standard operating
            procedures to guide the safe use of borescopes for inspection and
            hazards assessment.
          </p>
          <p>
            Our equipment is small, light-weight, and easily transported to the
            jobsite. &nbsp;Still photos or video can be recorded to a removable
            memory card and the video can be viewed and copied onto a computer
            for more detailed review and assessment. &nbsp;Our unit has been
            tested for areas that have potentially hazardous environments.&nbsp;
            It is flammable atmosphere rated and the same unit has been used by
            the military in many hazardous locations.
          </p>
          <p>
            With our borescope equipment, inspection of tight, hard-to-reach, or
            otherwise inaccessible hidden spaces can provide valuable
            information for assessment.&nbsp; Areas such as duct work, piping,
            wall cavities, inside of process equipment, etc. can be safely
            visually inspected up to a length of 5 m.&nbsp; The 6 mm diameter
            articulating tip allows the operator to see in all directions with
            the borescope camera tip, which can be inserted into very tight and
            normally inaccessible cavities to determine if contamination is
            present.
          </p>
          <p>
            With 5 levels of temperature controlled LED lighting built into the
            tip, the borescope is capable of visual inspection of dark and
            distant locations and adjusting the light for the unique situation.
          </p>
        </Paragraph>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Borescoping",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "SMS has the capability to inspect hard-to-reach or inaccessible places for hazardous material during D3 operations with borescope technology."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  },
  data: () => {
    return {};
  }
};
</script>
